import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comment-form"
export default class extends Controller {
  static targets = ['form', 'body', 'fileField'];

  connect() {
    // listen for file field changes to not exceed data-max-file-size in bytes to set on that field
    this.fileFieldTarget.addEventListener('change', this.validateFileSize.bind(this));
  }

  clearForm(event) {
    if (event.detail.success) {
      this.bodyTarget.value = '';
      this.fileFieldTarget.value = '';
    }
  }

  validateFileSize(event) {
    const maxFileSize = this.fileFieldTarget.dataset.maxFileSize;
    const file = this.fileFieldTarget.files[0];
    if (file.size > maxFileSize) {
      alert('File size exceeds the maximum allowed size');
      this.fileFieldTarget.value = '';
    }
  }
}