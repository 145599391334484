import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="attribution"
export default class extends Controller {
  static targets = [ "up", "upTransparent", "upOpaque" ]

  connect() {
    console.log("Hello from attribution controller")
    this.attributionHref = this.data.get("attribution-href")
  }

  toggleUp(event) {
    event.preventDefault()

    let del = !this.upOpaqueTarget.classList.contains("hidden")
    this.upTransparentTarget.classList.toggle("hidden")
    this.upOpaqueTarget.classList.toggle("hidden")
    // this.downTransparentTarget.classList.remove("hidden")
    // this.downOpaqueTarget.classList.add("hidden")

    this.updateAttribution("positive", del)
  }

  // toggleDown(event) {
  //   event.preventDefault()
  //
  //   let del = !this.downOpaqueTarget.classList.contains("hidden")
  //   this.upTransparentTarget.classList.remove("hidden")
  //   this.upOpaqueTarget.classList.add("hidden")
  //   this.downTransparentTarget.classList.toggle("hidden")
  //   this.downOpaqueTarget.classList.toggle("hidden")
  //
  //   this.updateAttribution("negative", del)
  // }

  updateAttribution(direction, del) {
    const url = this.data.get("url")
    const csrfToken = this.data.get("csrf-token")
    const data = new FormData()
    data.append("attribution[attribution_type]", direction)
    data.append("attribution[user_id]", this.data.get("user-id"))
    data.append("attribution[comment_id]", this.data.get("comment-id"))
    data.append("authenticity_token", csrfToken)
    data.append("delete", del ? "true" : "false")

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Accept": "application/json",
      },
      body: data
    })
  }
}
